import { useUserLocation } from 'lib/hooks/useUserLocation';
import { Inclusion } from 'lib/types/trip';
import { Dispatch, SetStateAction } from 'react';
import { BookingModal } from '../Modal/BookingModal';
import { filterInclusions } from './filterInclusions';

type Props = {
  isOpen: boolean;
  close: Dispatch<SetStateAction<boolean>>;
  inclusions: Inclusion[];
};

const Inclusions = ({ isOpen, close, inclusions }: Props) => {
  const location = useUserLocation();
  const userCountry = location ? location.country.code : null;
  const inclusionsWithConditionsApplied = filterInclusions(
    inclusions,
    userCountry
  );

  return (
    <BookingModal
      title="Inclusions"
      isOpen={isOpen}
      close={close}
      cssClasses="md:max-w-2xl"
      position="center"
    >
      <ul className="grid grid-cols-1 md:grid-cols-2 mx-5 md:mx-10 mb-5 md:mb-10 gap-y-2.5 gap-x-2">
        {inclusionsWithConditionsApplied.map((inclusion, index) => (
          <li
            className=" flex justify-strat items-center text-left text-sm font-medium text-dark-700 list-none before:content-[''] before:inline-block before:min-w-[0.375rem] before:min-h-[0.375rem] before:max-h-[0.375rem] before:bg-light-900 before:rounded-md before:mb-0.5 before:mr-2"
            key={index}
          >
            <span>{inclusion.title}</span>
          </li>
        ))}
      </ul>
    </BookingModal>
  );
};

export { Inclusions };
