import { Dropdown } from 'components/Dropdown/Dropdown';
import { TDropDownItem } from 'lib/types/dropdown';
import { RoomTip } from './tip';
import { AccomodationSuplement } from './supplement';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Room, SelectedRoom } from 'lib/types/trip';
import { useCurrency } from 'lib/hooks/useCurrency';
import { AccomodationCardTitle } from './title';
import { RoomIcon } from './room-icon';
import { useNumberFormatter } from 'lib/hooks/useNumberFormatter/useNumberFormatter';

type Props = {
  room: Room;
  travellerDropdown: TDropDownItem | null;
  setSelectedRooms: Dispatch<SetStateAction<SelectedRoom[]>>;
  selectedRooms: SelectedRoom[];
  defaultSelected: number;
};
const AccomodationCard = ({
  room,
  travellerDropdown,
  setSelectedRooms,
  selectedRooms,
  defaultSelected,
}: Props) => {
  const [data, setData] = useState<TDropDownItem[] | null>(null);
  const [roomDropdown, setRoomDropdown] = useState<TDropDownItem | null>(null);
  const { currency, currencyShouldBeShown } = useCurrency();
  const numberFormatter = useNumberFormatter();

  const fillDropDown = () => {
    if (travellerDropdown) {
      const sumSelectedRooms = selectedRooms
        .map((item) => (item.id === room.id ? 0 : item?.count))
        .reduce((partialSum, a) => partialSum + a, 0);

      const array: TDropDownItem[] = Array.from(
        { length: parseInt(travellerDropdown?.value) - sumSelectedRooms + 1 },
        (v, k): TDropDownItem => {
          return {
            label: (k + 0).toString() + ' people',
            value: (k + 0).toString(),
          };
        }
      ).filter((item) =>
        parseInt(item.value) % room.capacity !== 0 && !room.can_share
          ? false
          : true
      );

      setData(array);
      return array;
    }
    return null;
  };

  useEffect(() => {
    if (travellerDropdown) {
      const array = fillDropDown();
      if (array) {
        const defaultDropDown: TDropDownItem | null =
          defaultSelected > 0
            ? {
                label: `${defaultSelected} people`,
                value: `${defaultSelected}`,
              }
            : array[0] ?? {
                label: `0 people`,
                value: `0`,
              };

        setRoomDropdown(defaultDropDown);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [travellerDropdown, defaultSelected]);

  useEffect(() => {
    fillDropDown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRooms]);

  useEffect(() => {
    if (roomDropdown) {
      const selectedRoom: SelectedRoom = {
        id: room.id,
        count: parseInt(roomDropdown.value),
        hasError:
          parseInt(roomDropdown.value) % room.capacity !== 0 && !room.can_share
            ? true
            : false,
      };

      setSelectedRooms((el) => {
        const roomIndex = el.findIndex((item) => item.id === room.id);
        if (roomIndex >= 0) {
          let newEl =  JSON.parse(JSON.stringify(el));
          if (parseInt(roomDropdown.value) > 0) {
            newEl[roomIndex].count = parseInt(roomDropdown.value);
            newEl[roomIndex].hasError =
              parseInt(roomDropdown.value) % room.capacity !== 0 &&
              !room.can_share
                ? true
                : false;
          } else {
            newEl = newEl.filter((item:any, index:number) => index !== roomIndex);
          }
          return newEl;
        } else if (parseInt(roomDropdown.value) > 0) {
          return [...el, selectedRoom];
        }
        return el;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomDropdown]);

  return (
    <div>
      <div className="border border-light-800 rounded-md p-4 md:px-5 md:pt-5 md:pb-0">
        <div className="flex flex-col md:flex-row md:justify-between md:items-center md:pb-5 ">
          <div className="flex flex-row gap-5 flex-grow pb-4 md:pb-0">
            <RoomIcon capacity={room.capacity} />
            <div className="flex flex-col gap-[0.625rem]">
              <AccomodationCardTitle
                title={
                  (room.can_share ? 'Shared ' : '') +
                  (room.title || room.arr_name)
                }
                description={room.description}
              />
              {room.title && (
                <div className="font-semibold text-dark-500 text-sm">
                  {room.arr_name}
                </div>
              )}
            </div>
          </div>
          {room.single_supplement > 0 && (
            <div className=" md:hidden">
              <AccomodationSuplement />
            </div>
          )}
          <div className="flex justify-between md:w-[18.75rem] md:pl-[1.3rem] border-t md:border-t-0 md:border-l border-light-700 items-center pt-4 md:pt-0">
            {room.is_included ? (
              <div className="text-xs text-green-900 font-semibold">
                Included
              </div>
            ) : (
              <div className="flex items-center">
                <div className="text-sm text-dark-700 font-semibold">
                  {numberFormatter.format(room.prices[currency])}
                </div>
                {currencyShouldBeShown ? (
                  <div className="text-xs text-dark-700 font-semibold ml-0.5 mb-0.5">
                    ({currency})
                  </div>
                ) : null}
                <div className="text-sm text-dark-700 font-semibold ml-1 ">
                  / person
                </div>
              </div>
            )}

            <div className="w-[8.75rem]">
              {data && (
                <Dropdown
                  options={data}
                  selected={roomDropdown}
                  setSelected={setRoomDropdown}
                  placeholder=""
                  hasBorder={true}
                  hasArrow={true}
                  hasSearch={false}
                  menuStretchesToController
                  menuClassName="!drop-shadow-xl"
                />
              )}
            </div>
          </div>
        </div>
        {room.single_supplement > 0 && (
          <div className="hidden md:block">
            <AccomodationSuplement />
          </div>
        )}
      </div>
      {roomDropdown &&
        parseInt(roomDropdown.value) % room.capacity !== 0 &&
        !room.can_share && (
          <div className="mt-[0.625rem]">
            <RoomTip
              title="Important"
              titleColor="red"
              text={`This accommodation is for ${room.capacity} people. Please fill the room or select another option.`}
              icon="notification"
            />
          </div>
        )}
    </div>
  );
};
export { AccomodationCard };
