import { useQuery } from '@tanstack/react-query';
import { PaymentOptionsQueryResult } from 'lib/types/payment';
import { useEffect, useState } from 'react';
import { BookingGateway } from 'services/http/booking-gateway';
import { sessionIdService } from 'services/session-id/session-id.service';
import { useAppSelector } from 'state/hooks';

type Response = {
  isLoading: boolean;
  isPromotionalBookingActive: boolean;
  isStandardBookingActive: boolean;
  isTripInFullBookingActive: boolean;
};

const usePaymentOptions = (): Response => {
  const [isPromotionalBookingActive, setIsPromotionalBookingActive] =
    useState<boolean>(false);
  const [isStandardBookingActive, setIsStandardBookingActive] =
    useState<boolean>(false);
  const [isTripInFullBookingActive, setIsTripInFullBookingActive] =
    useState<boolean>(false);

  const URL = '/public/inventory/trip/payment-options';
  const sessionId = sessionIdService.get();
  const currencyHasBeenSet = useAppSelector(
    (state) => state.currency.hasBeenSet
  );

  const result = useQuery(
    ['payment-options', sessionId],
    async () => {
      const res = await BookingGateway.get<PaymentOptionsQueryResult>(URL);
      return res;
    },
    {
      staleTime: 1 * 60 * 1000,
      enabled: !!sessionId.length && currencyHasBeenSet,
    }
  );

  useEffect(() => {
    if (result.data) {
      const activePaymentOptions = result.data.result || [];

      setIsPromotionalBookingActive(activePaymentOptions.includes('deposit'));

      setIsStandardBookingActive(activePaymentOptions.includes('standard'));

      setIsTripInFullBookingActive(
        activePaymentOptions.includes('trip_in_full')
      );
    }
  }, [result.data?.result]);

  return {
    isLoading: result.isLoading,
    isPromotionalBookingActive,
    isStandardBookingActive,
    isTripInFullBookingActive,
  };
};

export { usePaymentOptions };
