import { Appearance, CssFontSource, CustomFontSource } from '@stripe/stripe-js';
import { TenantTheme } from 'lib/types/theme';

const getAppearance = (theme: TenantTheme): Appearance => {
  return {
    labels: 'floating',
    rules: {
      '.Tab--selected, .Tab--selected:focus, .Tab--selected:active': {
        outline: 'none',
        boxShadow: `0px 0px 4px 4px ${theme.primary['900-transparent']}`,
        borderColor: theme.primary[900],
      },
      '.Tab:focus': {
        outline: 'none',
        boxShadow: 'none',
      },
      '.Tab:active': {
        borderColor: '#e6e6e6',
      },
      '.Input': {
        fontWeight: '600',
        color: 'rgb(51, 53, 57)',
      },
      '.Input:focus': {
        outline: 'none',
        boxShadow: `0px 0px 4px 4px ${theme.primary['900-transparent']}`,
        borderColor: theme.primary[900],
        paddingTop: 'var(--c-inputFloatingPaddingTop)!important',
      },
      '.Input--invalid': {
        outline: 'none',
        boxShadow: '0px 0px 4px 4px #FE575410',
        color: 'border-color: rgb(254 87 84/var(--tw-border-opacity))',
        borderColor: '#FE5754',
      },
      '.Label': {
        fontSize: '.875rem',
        fontWeight: '600',
        color: 'rgb(115, 120, 130)',
      },
      '.Label--floating': {
        fontSize: '0.75rem',
        fontWeight: '600',
      },
      '.Error': {
        fontSize: '.75rem',
      },
    },
    variables: {
      fontFamily: 'proxima-nova',
      focusOutline: 'white',
      borderRadius: '.375rem',
    },
  };
};

const fonts: Array<CssFontSource | CustomFontSource> = [
  {
    family: 'proxima-nova',
    src:
      "url('https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')\n" +
      "      format('woff2'),\n" +
      "    url('https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')\n" +
      "      format('woff'),\n" +
      "    url('https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')\n" +
      "      format('opentype')",
    weight: '700',
    style: 'normal',
    display: 'swap',
  },
  {
    family: 'proxima-nova',
    src:
      "url('https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')\n" +
      "      format('woff2'),\n" +
      "    url('https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')\n" +
      "      format('woff'),\n" +
      "    url('https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')\n" +
      "      format('opentype')",
    weight: '600',
    style: 'normal',
    display: 'swap',
  },
  {
    family: 'proxima-nova',
    src:
      "url('https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')\n" +
      "      format('woff2'),\n" +
      "    url('https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')\n" +
      "      format('woff'),\n" +
      "    url('https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')\n" +
      "      format('opentype')",
    weight: '400',
    style: 'normal',
    display: 'swap',
  },
  {
    family: 'proxima-nova',
    src:
      "url('https://use.typekit.net/af/cebe0e/00000000000000003b9b3060/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')\n" +
      "      format('woff2'),\n" +
      "    url('https://use.typekit.net/af/cebe0e/00000000000000003b9b3060/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')\n" +
      "      format('woff'),\n" +
      "    url('https://use.typekit.net/af/cebe0e/00000000000000003b9b3060/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')\n" +
      "      format('opentype')",
    weight: '300',
    style: 'normal',
    display: 'swap',
  },
  {
    family: 'proxima-nova',
    src:
      "url('https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')\n" +
      "      format('woff2'),\n" +
      "    url('https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')\n" +
      "      format('woff'),\n" +
      "    url('https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')\n" +
      "      format('opentype')",
    weight: '500',
    style: 'normal',
    display: 'swap',
  },
];

export const stripeConfig = {
  fonts,
  getAppearance,
};
