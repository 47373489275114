import { useUserLocation } from '../useUserLocation';
import { phoneNumbers } from './data';

const usePhoneNumber = () => {
  const location = useUserLocation();

  if (!location) return { phoneNumber: phoneNumbers.default };

  const phoneNumber: string =
    phoneNumbers[location.country.code] ||
    phoneNumbers[location.continent] ||
    phoneNumbers.default;

  return { phoneNumber };
};

export { usePhoneNumber };
