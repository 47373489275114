import { ChangeDate } from './ChangeDate';
import { ChangeDate as ChangeDateModal } from '../changeDate/ChangeDate';
import { useState } from 'react';
import { Button } from 'components/Button/Button';
import { getStartDate } from './helper';
import { useAppSelector } from 'state/hooks';

type Props = {
  openInclusionsModal: () => void;
};

const TripInfoDetails = ({ openInclusionsModal }: Props) => {
  const [isChooseDateOpen, setIsChooseDateOpen] = useState<boolean>(false);
  const selectedDuration = useAppSelector((state) => state.product.duration);
  const durationText = selectedDuration?.text;
  const availability = useAppSelector((state) => state.product.availability);
  const flexibleDeparture = useAppSelector(
    (state) => state.product.flexibleDeparture
  );
  const startDate = flexibleDeparture ? 'Flexible' : getStartDate(availability);

  const openChangeDateModal = () => setIsChooseDateOpen(true);
  const closeChooseDateModal = () => setIsChooseDateOpen(false);

  return (
    <>
      <div className="hidden sm:grid border-b border-light-800  grid-cols-2">
        <div
          className={
            'px-5 my-5 flex flex-col justify-between gap-1 items-start border-light-800' +
            (startDate ? ' border-r' : '')
          }
        >
          <div className="text-xs text-dark-600 font-medium">Duration</div>
          <div className="text-sm text-dark-700 font-semibold">
            {durationText}
          </div>
        </div>
        {startDate ? (
          <div className="px-5 my-5 flex flex-col justify-between gap-1 items-start">
            <div className="text-xs text-dark-600 font-medium">Start Date</div>
            <div className="text-sm text-dark-700 font-semibold">
              {startDate}
            </div>
          </div>
        ) : (
          <div></div>
        )}
        <div className="m-5 mt-0">
          <ChangeDate openChangeDateModal={openChangeDateModal} />
        </div>
      </div>
      <ChangeDateModal
        isOpen={isChooseDateOpen}
        close={closeChooseDateModal}
        afterSubmit={() => {}}
      />

      <div className="sm:hidden bg-light-600 border-b border-light-800 flex justify-between ">
        <div className="flex items-center justify-between pl-5 my-5">
          <div className=" ">
            <div className="text-xs text-dark-600 font-medium">Duration</div>
            <div className="text-sm text-dark-700 font-semibold mt-1">
              {durationText}
            </div>
          </div>
          {startDate ? (
            <>
              <div className="border-r border-light-800 h-[2.2rem] w-1 mx-2.5"></div>
              <div className="">
                <div className="text-xs text-dark-600 font-medium">
                  Start Date
                </div>
                <div className="text-sm text-dark-700 font-semibold  mt-1">
                  {startDate}
                </div>
              </div>
            </>
          ) : (
            <div></div>
          )}
        </div>
        <div className=" flex justify-end items-center pr-5 my-5">
          <Button
            size="extra-small"
            text="Inclusions"
            type="primary-border"
            clickHandler={openInclusionsModal}
            className={'!bg-opacity-0'}
          />
          <div className="w-2.5"></div>
          <ChangeDate openChangeDateModal={openChangeDateModal} />
        </div>
      </div>
    </>
  );
};

export { TripInfoDetails };
