import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CurrencyKeys } from 'lib/types/trip';

type UserState = {
  currency: CurrencyKeys | null;
  saleRegisterId: number | null;
  cid: number | null;
};

const initialState: UserState = {
  currency: null,
  saleRegisterId: 0,
  cid: 0,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrency(state, action: PayloadAction<CurrencyKeys>) {
      state.currency = action.payload;
    },
    setSalesRegisterId(state, action: PayloadAction<number | null>) {
      state.saleRegisterId = action.payload;
    },
    setCid(state, action: PayloadAction<number | null>) {
      state.cid = action.payload;
    },
  },
});

export const { setCurrency, setSalesRegisterId, setCid } = userSlice.actions;
export default userSlice.reducer;
