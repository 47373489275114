import { Image } from 'components/Image/Image';
import { useTenant } from 'lib/hooks/useTenant';
import { TravellerCount } from 'lib/types/traveller-count';

export type Props = {
  travellerCount: TravellerCount;
  shouldShow: boolean;
  isMobile?: boolean;
};

function TravellersBookInfo({
  travellerCount,
  isMobile = false,
  shouldShow,
}: Props) {
  const { isDA } = useTenant();

  return shouldShow ? (
    <div
      id="travellers-book-info"
      className={
        ' h-16 md:h-20 items-center text-dark-700 text-xs leading-4 font-semibold px-5 sm:rounded-t-md ' +
        (isMobile
          ? ` flex gap-2.5 ${
              isDA ? ' bg-dealsaway-800 ' : ' bg-purple-800 '
            } border-t ${
              isDA ? ' border-dealsaway-900' : ' border-purple-900 '
            } `
          : `hidden sm:flex gap-2.5 ${
              isDA ? ' bg-dealsaway-800 ' : ' bg-[#EEE6FD40] '
            } `)
      }
    >
      <div className="min-w-[2.5rem] min-h-[2.5rem] w-10 h-10 md:h-[1.875rem] md:w-[1.875rem] md:min-h-[1.875rem] md:min-w-[1.875rem] flex justify-center items-center">
        <div className="h-[1.875rem] w-[1.875rem] min-h-[1.875rem] min-w-[1.875rem] flex items-center justify-center">
          <Image path="booking/icons/airplane.svg" alt="airplane icon" />
        </div>
      </div>
      <div className="text-sm  leading-5 font-bold">
        Limited spots.{' '}
        <span className="underline">{travellerCount?.result?.count}</span> trips
        booked in <span className="whitespace-nowrap">last 7 days.</span>
      </div>
    </div>
  ) : null;
}

export { TravellersBookInfo };
