import { useUserLocation } from '../useUserLocation';
import { officeData, officeNameByLocation } from './data';
import { TOffice } from './types';

function useOffice(): TOffice {
  const location = useUserLocation();

  if (!location) return officeData[officeNameByLocation.default];

  const officeName =
    officeNameByLocation[location.continent] || officeNameByLocation.default;

  const office = officeData[officeName];

  return office;
}

export { useOffice };
