import { useTenant } from 'lib/hooks/useTenant';
import { Step } from './Step';

type Props = {
  stripeIsLoading: boolean;
};

const Steps = ({ stripeIsLoading }: Props) => {
  const { isDA, isGwat } = useTenant();

  return (
    <div className="p-0 md:p-10 bg-light-600 md:border border-light-800 rounded-md md:shadow-[0px_5px_5px_0px_#17181a0d]">
      <Step
        order={1}
        status="complete"
        border="green"
        title="Complete your booking"
        description="Well done! You've taken the first & hardest step. Now the fun begins!"
        isLoading={stripeIsLoading}
      />
      <Step
        order={2}
        status="complete"
        border="green"
        title="Confirmation email"
        description="In your inbox, you'll find a copy of your booking details and payment receipt."
        isLoading={stripeIsLoading}
      />
      {isGwat ? (
        <>
          <Step
            order={3}
            status="todo"
            border="dashed"
            title="Setup your gWorld account & payment plan"
            description={`gWorld is your personalised portal & mobile app where you can get everything in order for your trip, access our Marketplace, Academy and meet others going to the same place. Please click on the link that has been sent to your email & take a few minutes to create your account.</br></br>Complete your profile, watch the welcome video inside & choose the payment plan you want.`}
          />
          <Step
            order={4}
            status="todo"
            border="none"
            title="Now the fun begins!"
            description="We&#39;ll assign you your very own personal Trip Coordinator who&#39;ll be in touch to introduce themselves in the next day or so. They&#39;re going to be by your side throughout the entire process of making this travel dream a reality! Welcome on board again!"
            excludeUnderline
          />
        </>
      ) : null}
      {isDA ? (
        <Step
          order={3}
          status="todo"
          border="none"
          title="Welcome call from your dedicated Trip Coordinator"
          description="We're going to assign you someone to personally look after you from here until you safely return; expect to hear from us within the next 48 hours. We'll double check your details, answer any new questions and make sure everything is ok. Then, the countdown to holiday time begins!"
          excludeUnderline
        />
      ) : null}
    </div>
  );
};

export { Steps };
