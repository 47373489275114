type Props = {
  color: string;
};

export const ArrowRight = ({ color }: Props) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9697 9.46967C12.2626 9.17678 12.7374 9.17678 13.0303 9.46967L18.0303 14.4697C18.3232 14.7626 18.3232 15.2374 18.0303 15.5303L13.0303 20.5303C12.7374 20.8232 12.2626 20.8232 11.9697 20.5303C11.6768 20.2374 11.6768 19.7626 11.9697 19.4697L16.4393 15L11.9697 10.5303C11.6768 10.2374 11.6768 9.76256 11.9697 9.46967Z"
        fill={color}
      />
    </svg>
  );
};
