import { TDropDownItem } from '../../lib/types/dropdown';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { DropdownMenuItems } from './Dropdown.menu-items';
import { DropdownSearch } from './Dropdown.search';

type Props = {
  filterString: string;
  handleFilterStringChange: (e: ChangeEvent<HTMLInputElement>) => void;
  options: TDropDownItem[];
  selected: TDropDownItem | null;
  setSelected: Dispatch<SetStateAction<TDropDownItem<{}> | null>>;
  hasFlagImage: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  hasSearch?: boolean;
  stretchesToController: boolean;
  className: string;
  onChange?: () => void;
  isShowValue?: boolean;
};

const DropdownMenu = ({
  filterString,
  handleFilterStringChange,
  options,
  selected,
  setSelected,
  hasFlagImage,
  setIsOpen,
  hasSearch = true,
  stretchesToController,
  className,
  onChange,
  isShowValue,
}: Props) => {
  return (
    <div
      data-testid="dropdown__menu"
      className={
        className +
        ' absolute z-50 top-full mt-2.5 bg-light-600 border border-light-900 p-2 rounded-md select-none drop-shadow-md' +
        (stretchesToController ? ' left-0 right-0' : ' min-w-[18rem]')
      }
    >
      {hasSearch && (
        <DropdownSearch
          filterString={filterString}
          handleFilterStringChange={handleFilterStringChange}
        />
      )}
      <DropdownMenuItems
        options={options}
        selected={selected}
        setSelected={setSelected}
        hasFlagImage={hasFlagImage}
        setIsOpen={setIsOpen}
        onChange={onChange}
        isShowValue={isShowValue}
      />
    </div>
  );
};
export { DropdownMenu };
