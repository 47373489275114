import { Button } from 'components/Button/Button';
import { useTravellerCountQuery } from 'lib/react-query/Queries/useTravellerCountQuery';
import { MouseEvent } from 'react';

type Props = {
  submitHandler: (e: MouseEvent<HTMLElement>) => void;
  isDisabled: boolean;
  isLoading: boolean;
  submitButtonText: string;
};

const ContinueCta = ({
  submitHandler,
  isDisabled,
  isLoading,
  submitButtonText,
}: Props) => {
  const {
    isLoading: travellersCountLoading,
    data: travellerCount,
    shouldShow,
  } = useTravellerCountQuery();

  return (
    <div id="bottom-action-bar">
      <div className="w-full flex gap-2.5 items-center justify-between p-5 h-[5.625rem] border-t border-light-900">
        {!travellersCountLoading &&
        travellerCount?.result?.count &&
        shouldShow ? (
          <div className="flex items-center gap-2.5">
            <p className="text-xl">🔥</p>
            <p className="text-sm font-medium text-dark-800 !leading-5">
              Limited spots.{' '}
              <span className="text-purple-900 font-bold">
                {travellerCount?.result?.count}
              </span>{' '}
              trips booked in{' '}
              <span className="whitespace-nowrap">last 7 days.</span>
            </p>
          </div>
        ) : (
          <div></div>
        )}
        <Button
          text={submitButtonText}
          clickHandler={submitHandler}
          size="medium"
          type="primary"
          isDisabled={isDisabled}
          isLoading={isLoading}
          className="min-w-[8.75rem] h-[3.125rem]"
        />
      </div>
    </div>
  );
};

export { ContinueCta };
