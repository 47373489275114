import { Dropdown } from 'components/Dropdown/Dropdown';
import { TDropDownItem } from 'lib/types/dropdown';
import { RoomTip } from './accomodation/tip';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useAppSelector } from 'state/hooks';

type Props = {
  minCompanion: number;
  maxCompanion: number;
  isLoading: boolean;
  isShowTip?: boolean;
  travellerDropdown: TDropDownItem | null;
  setTravellerDropdown: Dispatch<SetStateAction<TDropDownItem | null>>;
  onChangeTravellerDropdown: any;
};

const TravellerNumber = ({
  minCompanion,
  maxCompanion,
  isLoading,
  isShowTip = true,
  travellerDropdown,
  setTravellerDropdown,
  onChangeTravellerDropdown,
}: Props) => {
  const [data, setData] = useState<TDropDownItem[] | null>(null);
  const travellerNumber = useAppSelector(
    (state) => state.product.travellerNumber
  );

  useEffect(() => {
    const array: TDropDownItem[] = Array.from(
      { length: maxCompanion - minCompanion + 1 },
      (v, k): TDropDownItem => {
        return {
          label: (k + minCompanion).toString(),
          value: (k + minCompanion).toString(),
        };
      }
    );
    setData(array);
    const value =
      travellerNumber > 1 ? travellerNumber : Number(array[0].value);
    const tempDefault: TDropDownItem | null = {
      label: `${value}`,
      value: `${value}`,
    };

    travellerDropdown?.value !== tempDefault.value &&
      setTravellerDropdown(tempDefault);
  }, [maxCompanion, minCompanion, travellerNumber]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [travellerNumber]);

  return (
    data && (
      <div>
        <h3 className="text-lg font-semibold text-dark-800 mb-5">
          Select No. of Travellers
        </h3>
        <div className="w-full md:w-[20.313rem]">
          <Dropdown
            options={data}
            selected={travellerDropdown}
            setSelected={setTravellerDropdown}
            placeholder="Number of Travellers"
            hasBorder={true}
            hasArrow={true}
            hasSearch={false}
            menuStretchesToController
            onChange={onChangeTravellerDropdown}
            isLoading={isLoading}
          />
        </div>
        {isShowTip && (
          <div className="mt-[0.625rem] md:mt-5">
            <RoomTip
              text={`Please be aware you can book for a minimum of ${minCompanion} and maximum number of
          ${maxCompanion} people`}
              title="Tip"
              titleColor="yellow"
              icon="notification"
            />
          </div>
        )}
      </div>
    )
  );
};

export { TravellerNumber };
