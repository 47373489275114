import { TDropDownItem } from '../../lib/types/dropdown';
import { Dispatch, SetStateAction } from 'react';
import { Image } from '../../components/Image/Image';
import 'flagpack/dist/flagpack.css';
import { ArrowUp } from 'assets/icons/ArrowUp';

type Props = {
  selected: TDropDownItem | null;
  placeholder?: string;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  hasBorder: boolean;
  hasFlagImage: boolean;
  hasArrow?: boolean;
  className: string;
  isLoading: boolean;
};

const DropdownController = ({
  selected,
  placeholder,
  isOpen,
  setIsOpen,
  hasBorder,
  hasFlagImage,
  hasArrow = false,
  className,
  isLoading,
}: Props) => {
  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  const keyPressHandler = (event: any) => {
    if (event.key === 'Enter') {
      setIsOpen(!isOpen);
    }
  };

  if (isLoading) {
    return (
      <div className="p-4 rounded-md flex min-h-[3rem] md:min-h-[3.625rem] animate-pulse bg-light-800 "></div>
    );
  }

  return (
    <div
      tabIndex={0}
      data-testid="dropdown__controller"
      className={
        className +
        ' p-4 rounded-md flex min-h-[3rem] md:min-h-[3.625rem] cursor-pointer realtive ' +
        (hasBorder ? ' border border-light-900' : '')
      }
      style={{ boxShadow: '0px 3px 5px rgba(23, 24, 26, 0.03)' }}
      onClick={toggleIsOpen}
      onKeyPress={keyPressHandler}
    >
      {hasArrow && (
        <div className=" absolute top-[1.563rem] right-[1.563rem] w-[0.625rem] ">
          <ArrowUp color="#737882" cssClass={!isOpen ? ' rotate-180 ' : ''} />
        </div>
      )}
      {selected ? (
        <div className="flex items-center gap-1.5">
          {!hasFlagImage && selected.image ? (
            <div className="w-5 h-3">
              <Image
                path={selected.image}
                alt={selected.label}
                crop={{
                  width: '300',
                  height: '200',
                }}
              />
            </div>
          ) : null}
          {hasFlagImage && selected.image ? (
            <span className={`fp fp-md fp-rounded ${selected.image}`}></span>
          ) : null}
          <div className="text-sm font-semibold text-dark-800">
            {selected.label}
          </div>
        </div>
      ) : (
        <div className="font-semibold text-dark-600 select-none">
          {placeholder}
        </div>
      )}
    </div>
  );
};

export { DropdownController };
