import { configureStore } from '@reduxjs/toolkit';
import userSlice from './slices/user';
import productSlice from './slices/product';
import modalSlice from './slices/modal';
import currencySlice from './slices/currency';
import locationSlice from './slices/location';
import roomSlice from './slices/room';
import flagSlice from './slices/flags'

export const store = configureStore({
  reducer: {
    user: userSlice,
    product: productSlice,
    modal: modalSlice,
    currency: currencySlice,
    location: locationSlice,
    room: roomSlice,
    flag:flagSlice
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
