import { ThemeProvider } from '@mui/material';
import {
  DateField,
  FieldSelectedSections,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import {
  Dispatch,
  forwardRef,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { getCommonTheme } from './config';
import { createTheme } from '@mui/material';
import { useTenantTheme } from 'lib/hooks/useTenantTheme';
import { Image } from 'components/Image/Image';

type Props = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  date: Date | null;
  isValid?: boolean;
  tabIndex?: number;
  setDate: any;
};

const DatePickerController = forwardRef<HTMLDivElement, Props>(
  ({ isOpen, setIsOpen, date, isValid = true, tabIndex, setDate }, ref) => {
    const [tempShow, setTempShow] = useState<boolean>(false);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [selectedSection, setSelectedSection] =
      useState<FieldSelectedSections>(null);
    const tenantTheme = useTenantTheme();

    const keyPressHandler = (event: any) => {
      if (event.key === 'Enter') {
        setIsOpen(!isOpen);
      }
    };

    const handleClickEvent = (e: any) => {
      if (
        containerRef &&
        containerRef.current &&
        containerRef.current.contains(e.target)
      ) {
        setTempShow(true);
        inputRef.current?.focus();
        setSelectedSection(0);
      } else {
        setTempShow(false);
        setSelectedSection(null);
      }
    };

    const handleFocus = () => {
      setTempShow(true);
      inputRef.current?.focus();
      setSelectedSection(0);
    };

    const handleClickInDateField = (e: any) => {
      e.stopPropagation();
      setTempShow(true);
      inputRef.current?.focus();
    };

    const handleChange = (value: dayjs.Dayjs | null) => {
      setDate(value);
    };

    useEffect(() => {
      window.addEventListener('click', handleClickEvent);
      const container = document.querySelector('#date-picker-container');
      container?.addEventListener('focus', handleFocus);

      return () => {
        window.removeEventListener('click', handleClickEvent);
        container?.removeEventListener('focus', handleFocus);
      };
    }, []);

    const theme = createTheme({
      ...getCommonTheme(tenantTheme),
      components: {
        MuiOutlinedInput: {
          styleOverrides: {
            input: {
              padding: 0,
              fontSize: '0.875rem',
              lineHeight: '0.875rem',
              color: '#333539',
              fontWeight: 600,
              height: 'auto',
              minHeight: 'auto',
              opacity: tempShow || date ? '100%' : '0%',
              cursor: tempShow || date ? 'default' : 'pointer',
            },
          },
        },
      },
    });

    return (
      <div
        id="date-picker-container"
        tabIndex={tabIndex}
        ref={ref}
        onKeyPress={keyPressHandler}
        className={
          'select-none cursor-pointer pr-4 border rounded-md items-center flex justify-between transition-all' +
          (isOpen
            ? ' border-teal-900 shadow-[0px_0px_4px_4px_#0083A010]'
            : ' border-light-900 focus-within:border-teal-900 focus-within:shadow-primary-900') +
          (isValid
            ? ' '
            : ' border-red-900 shadow-[4px_4px_4px_4px_#FE575410] focus-within:border-red-900 focus-within:shadow-[4px_4px_4px_4px_#FE575410] text-red-900')
        }
      >
        <div
          className="flex-grow relative pr-2.5 pt-[1.875rem] pb-[0.625rem] pl-4 h-14"
          ref={containerRef}
        >
          <div
            className={
              'absolute text-dark-600  text-sm leading-4 transition-all font-semibold ' +
              (!isValid && ' text-red-900') +
              (date || tempShow
                ? ' !text-xs top-2.5 left-4 text-opacity-75'
                : ' top-5 left-4 peer-focus:top-2.5 peer-focus:text-xs peer-focus:text-opacity-75')
            }
          >
            Date of birth
          </div>
          <div onClick={handleClickInDateField}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <ThemeProvider theme={theme}>
                <DateField
                  format="DD-MM-YYYY"
                  value={date ? dayjs(date) : null}
                  onChange={handleChange}
                  slotProps={{
                    textField: {
                      inputRef: inputRef,
                    },
                  }}
                  selectedSections={selectedSection}
                  onSelectedSectionsChange={setSelectedSection}
                  disableFuture
                  maxDate={dayjs(new Date())}
                />
              </ThemeProvider>
            </LocalizationProvider>
          </div>
        </div>
        <div onClick={() => setIsOpen(!isOpen)}>
          <Image
            path="booking/icons/calendar-traveller.svg"
            alt="calendar icon"
          />
        </div>
      </div>
    );
  }
);

export { DatePickerController };
