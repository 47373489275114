import { Image } from 'components/Image/Image';

type Props = {
  year: number;
};

const Da = ({ year }: Props) => {
  return (
    <div className="bg-light-700">
      <div className="p-5">
        <div className="text-lg font-semibold leading-6 text-dark-800 text-center">
          The Global Work & Travel Group
        </div>
        <p className="text-dark-600 text-center text-sm font-semibold leading-5 mt-2.5">
          DealsAway.com is proudly part of the{' '}
          <span className="whitespace-nowrap">Global Work & Travel</span> family
          of brands, a worldwide leader in life-changing travel & technology.
        </p>
        <div className="mt-5 grid grid-cols-2 md:flex justify-center items-center gap-5">
          <a
            href="https://www.globalworkandtravel.com/"
            target="_blank"
            rel="noreferrer"
            className="h-12 md:h-16 flex justify-center items-center flex-grow rounded-md border border-light-900 md:max-w-[12.5rem]"
          >
            <div>
              <Image
                path="booking/global-work-and-travel-logo.svg"
                alt="global work and travel logo"
                className="!object-contain !w-24 md:!w-[7.5rem]"
                isCloudinary={true}
                isLazyLoad={false}
              />
            </div>
          </a>
          <a
            href="https://dealsaway.com/"
            target="_blank"
            rel="noreferrer"
            className="h-12 md:h-16 flex justify-center items-center flex-grow rounded-md border border-light-900 md:max-w-[12.5rem]"
          >
            <div>
              <Image
                path="https://s3.amazonaws.com/dealsaway.website.assets/logo_2.svg"
                alt="dealsaway logo"
                className="!object-contain !w-32 md:!w-40"
                isCloudinary={false}
                isLazyLoad={false}
              />
            </div>
          </a>
        </div>
      </div>
      <div className="p-5 bg-light-800 flex flex-col md:flex-row justify-center items-center gap-2.5">
        <div className="flex items-center gap-1">
          <a
            href="http://instagram.com/dealsaway"
            target="_blank"
            rel="noreferrer"
          >
            <Image path="booking/icons/instagram.svg" alt="instagram icon" />
          </a>
          <a
            href="https://www.facebook.com/dealsaway"
            target="_blank"
            rel="noreferrer"
          >
            <Image path="booking/icons/facebook.svg" alt="facebook icon" />
          </a>
          <a
            href="https://www.linkedin.com/company/dealsaway"
            target="_blank"
            rel="noreferrer"
          >
            <Image path="booking/icons/linkedin.svg" alt="linkedin icon" />
          </a>
        </div>
        <div className="text-dark-600 text-center text-xs font-semibold leading-5">
          © Copyright {year} DealsAway.com Pty Ltd. ACN: 641 656 877. All Rights
          Reserved.
        </div>
      </div>
    </div>
  );
};

export { Da };
