import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getTravellerPayload } from 'lib/helper/traveller/traveller';
import { useCurrency } from 'lib/hooks/useCurrency';
import { useRudderStack } from 'lib/hooks/useRudderStack';
import { useUserLocation } from 'lib/hooks/useUserLocation';
import { SelectionAvailability } from 'lib/types/availability';
import {
  CreateTravellerQueryResult,
  GetTravellerResult,
} from 'lib/types/create-traveller';
import { SelectedRoom } from 'lib/types/trip';
import { QueryError } from 'lib/types/utility';
import { BookingGateway } from 'services/http/booking-gateway';
import { sentryService } from 'services/sentry/sentry';
import { AppToastService } from 'components/Toast/AppToastService';
import { useCustomNavigate } from '../../hooks/useCustomNavigate';
import { stagePathConfig } from 'data/stage';
import { sessionIdService } from '../../../services/session-id/session-id.service';
import { checkTravellerData } from 'lib/helper/checkTravellerData';
import * as Sentry from '@sentry/react';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { setCid, setSalesRegisterId } from 'state/slices/user';
import { setInvoiceId } from 'state/slices/product';

export type CreateTravellerPayload = {
  email: string;
  firstName: string;
  lastName: string;
  phonePrefix: string | undefined;
  phoneNumber: string;
  dateOfBirth: Date;
  flexibleDeparture?: boolean;
  selectedAvailability?: SelectionAvailability;
  selectedRooms: SelectedRoom[];
};

const useCreateTraveller = () => {
  const URL = '/public/booking/setup';

  const flexibleDeparture = useAppSelector(
    (state) => state.product.flexibleDeparture
  );
  const queryClient = useQueryClient();
  const { customerRegistration } = useRudderStack();
  const { currency } = useCurrency();
  const location = useUserLocation();
  const countryName = location ? location.country.name : '';
  const navigate = useCustomNavigate();
  const duration = useAppSelector((state) => state.product.duration);
  const selectedDuration = duration?.id;
  const availability = useAppSelector((state) => state.product.availability);
  const dispatch = useAppDispatch();
  const travellerNumber = useAppSelector(
    (state) => state.product.travellerNumber
  );

  return useMutation<
    CreateTravellerQueryResult,
    QueryError,
    CreateTravellerPayload,
    unknown
  >(
    ['create-traveller'],
    async ({
      email,
      firstName,
      lastName,
      phonePrefix,
      phoneNumber,
      dateOfBirth,
      flexibleDeparture: flexibleDeparturePayload,
      selectedAvailability: selectedAvailabilityPayload,
      selectedRooms,
    }: CreateTravellerPayload) => {
      const payload = getTravellerPayload({
        selectedAvailability: selectedAvailabilityPayload ?? availability,
        selectedDuration,
        email,
        firstName,
        lastName,
        phonePrefix,
        phoneNumber,
        dateOfBirth,
        travellerNumber,
        is_departure_flexible: flexibleDeparturePayload ?? flexibleDeparture,
        selectedRooms,
      });

      const response = await BookingGateway.post<CreateTravellerQueryResult>(
        URL,
        payload
      );

      return response;
    },
    {
      onSuccess(data, variables) {
        if (!checkTravellerData(data.result.customer)) {
          Sentry.captureException(
            `Incomplete traveller data was returned by createTraveller.`,
            {
              tags: {
                query: 'create traveller',
              },
              extra: {
                data,
              },
            }
          );
        }

        const travellerData: GetTravellerResult = {
          success: true,
          result: data.result.customer,
        };
        queryClient.setQueryData(
          ['get-traveller-info', sessionIdService.get()],
          travellerData
        );
        dispatch(setSalesRegisterId(data.result.customer.sales_register_id));
        dispatch(setCid(data.result.customer.cid));
        dispatch(setInvoiceId(data.result.customer.invoice_id));
        queryClient.invalidateQueries({ queryKey: ['promotions'] });
        sentryService.setUser({
          id: data.result.cid ? String(data.result.cid) : undefined,
          email: variables?.email,
          username:
            variables?.firstName || variables?.lastName
              ? variables?.firstName + ' ' + variables?.lastName
              : undefined,
          extra: {
            salesRegisterId: data.result.sales_register_id
              ? String(data.result.sales_register_id)
              : undefined,
            currency,
            location: countryName,
            sessionId: sessionIdService.get(),
          },
        });
        customerRegistration(
          variables.email,
          variables.firstName,
          variables.lastName,
          variables.phonePrefix
            ? variables.phonePrefix + variables.phoneNumber
            : variables.phoneNumber,
          data.result.cid,
          data.result.sales_register_id
        );
      },
      onError(error) {
        AppToastService.error(error?.message ?? 'Oops! Something went wrong!');
        navigate(stagePathConfig.traveller);
      },
    }
  );
};

export { useCreateTraveller };
