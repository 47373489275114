import { useGotToGo } from 'lib/react-query/Queries/useGotToGo';
import { useEffect, useState } from 'react';
import { Input } from '../Input/Input';
import { EmailMeButton } from './email-me-button';
import 'react-toastify/dist/ReactToastify.css';
import { useRudderStack } from 'lib/hooks/useRudderStack';
import { AppToastService } from '../Toast/AppToastService';
import { getEmailValidation } from 'lib/helper/validation/validation';
import { TTravellerSubmit } from 'lib/types/traveller';
import { TRoomSubmit } from 'lib/types/room';
import { useLocation } from 'react-router-dom';
import { stagePathConfig } from 'data/stage';
import { ValidationHelper } from 'lib/helper/validation/validationHelper';
import { useAppSelector } from 'state/hooks';
import { Image } from 'components/Image/Image';
import { Retention } from '../../lib/helper/retention';

type TValidation = {
  isValid: boolean;
  message: string;
};

const initValidation: TValidation = {
  isValid: true,
  message: '',
};

const GotToGo = ({
  isCreateTravellerLoading,
  createTravellerData,
  submitCreateTraveller,
  isCreateTravellerError,
  travellerState,
  createTravellerIsGotToGo,
  setCreateTravellerIsGotToGo,
  roomHasError,
  submitRoomIsGotToGo,
  setSubmitRoomIsGotToGo,
  isRoomLoading,
  roomData,
  isRoomError,
  submitRoom,
  selectedRooms,
}: TTravellerSubmit & TRoomSubmit) => {
  const { pathname } = useLocation();
  const [email, setEmail] = useState<string>('');
  const [validation, setValidation] = useState<TValidation>(initValidation);
  const {
    isLoading: isGotToGoLoading,
    data: gotToGoData,
    mutate: submitGotToGo,
  } = useGotToGo();
  const { gotTogo } = useRudderStack();
  const isLoading =
    isGotToGoLoading ||
    (createTravellerIsGotToGo && isCreateTravellerLoading) ||
    (submitRoomIsGotToGo && isRoomLoading);
  const travellerValidator = new ValidationHelper();
  const hasDepartureDate = useAppSelector(
    (state) => state.product.hasDepartureDate
  );

  const validateGotToGo = (): boolean => {
    if (email.length <= 0) {
      setValidation({
        isValid: false,
        message: "Email can't be empty!",
      });
      return false;
    }

    const isEmailValid = getEmailValidation(email);
    if (!isEmailValid) {
      setValidation({
        isValid: false,
        message: "That's not a valid email address!",
      });
      return false;
    }

    setValidation({
      isValid: true,
      message: '',
    });
    return true;
  };

  const roomStageHandler = () => {
    if (roomHasError) {
      gotTogo(email);
      submitGotToGo(email);
    } else {
      setSubmitRoomIsGotToGo(true);
      submitRoom(hasDepartureDate ? undefined : true);
      Retention.suppress();
    }
  };

  const travellerStageHandler = () => {
    const isTravellerDataValid = travellerValidator.checkFieldsValidation(
      travellerState.firstName,
      travellerState.lastName,
      travellerState.phonePrefix?.value,
      travellerState.phoneNumber,
      travellerState.date,
      travellerState.email
    );
    if (!isTravellerDataValid) {
      gotTogo(email);
      submitGotToGo(email);
      Retention.suppress();
    } else {
      setCreateTravellerIsGotToGo(true);
      submitCreateTraveller({
        email: travellerState.email,
        firstName: travellerState.firstName,
        lastName: travellerState.lastName,
        phonePrefix: travellerState.phonePrefix?.label,
        phoneNumber: travellerState.phoneNumber,
        // NOTE: We have checked for date not being null in the validation
        // above, so we provide `!` as typescript is dumb to understand it
        dateOfBirth: travellerState.date!,
        flexibleDeparture: hasDepartureDate ? undefined : true,
        selectedRooms,
      });
    }
  };

  const clickHandler = () => {
    const isValid = validateGotToGo();
    if (!isValid) return;

    if (pathname === stagePathConfig.rooms) {
      roomStageHandler();
    }
    if (pathname === stagePathConfig.traveller) {
      travellerStageHandler();
    }
  };

  useEffect(() => {
    if (isRoomLoading) return;
    if (!submitRoomIsGotToGo) return;

    if (
      roomData &&
      roomData.success &&
      !isRoomError &&
      roomData.result.updated
    ) {
      gotTogo(email);
      submitGotToGo(email);
    } else if (isRoomError) {
      AppToastService.error('Oops! Something went wrong!');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRoomLoading, roomData, isRoomError]);

  useEffect(() => {
    if (isCreateTravellerLoading) return;
    if (!createTravellerIsGotToGo) return;

    if (
      createTravellerData &&
      createTravellerData.success &&
      !isCreateTravellerError
    ) {
      gotTogo(email);
      submitGotToGo(email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateTravellerLoading, createTravellerData]);

  useEffect(() => {
    if (isGotToGoLoading || !gotToGoData) return;

    if (gotToGoData.success) {
      AppToastService.success('We’ve sent you an email. Check your inbox!');
    } else {
      AppToastService.error('Oops! Something went wrong!');
    }
    if (createTravellerIsGotToGo) {
      setCreateTravellerIsGotToGo(false);
    }
    if (submitRoomIsGotToGo) {
      setSubmitRoomIsGotToGo(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGotToGoLoading, gotToGoData]);

  return (
    <section className="sm:w-[23.125rem] flex flex-col gap-5 bg-light-600 bg-opacity-50 md:bg-opacity-100 md:bg-light-600 p-5 md:p-0">
      <div className="flex gap-2 items-center md:hidden">
        <div className="w-6 h-6 flex justify-center items-center">
          <Image path="booking/icons/info.svg" alt="info icon" />
        </div>
        <h3 className="font-semibold text-lg leading-5 text-dark-800">
          Got to go?
        </h3>
      </div>
      <div className="bg-yellow-800 rounded-md p-5 font-semibold text-sm leading-5 text-dark-700">
        <h3 className="hidden md:block font-semibold text-lg leading-5 text-dark-800 mb-2.5">
          Got to go?
        </h3>
        <p>
          Type in your email address and we’ll send you a link so you can resume
          where you left off.
        </p>
      </div>
      <Input
        placeholder="Email"
        value={email}
        changeInput={setEmail}
        isError={!validation.isValid}
        ErrorMessage={validation.message}
        isDisable={isLoading}
        Button={
          <EmailMeButton
            clickHandler={clickHandler}
            isDisabled={isLoading}
            isLoading={isLoading}
          />
        }
      />
    </section>
  );
};

export { GotToGo };
